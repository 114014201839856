import { buildSchema } from '@camberi/firecms';

export type Policy = {
  title: string;
  content: string;
  meta: {
    timeCreated?: Date;
    timeUpdated?: Date;
  };
};

export const policySchema = buildSchema<Policy>({
  name: 'Policy',
  customId: {
    policy: 'Policy',
  },
  properties: {
    title: {
      title: 'Title',
      validation: { required: true },
      dataType: 'string',
    },
    content: {
      title: 'Content',
      validation: { required: true },
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    meta: {
      title: 'Metadata',
      dataType: 'map',
      properties: {
        timeCreated: {
          title: 'Time created',
          dataType: 'timestamp',
          autoValue: 'on_create',
        },
        timeUpdated: {
          title: 'Time updated',
          dataType: 'timestamp',
          autoValue: 'on_update',
        },
      },
    },
  },
});
