import { buildProperty, buildSchema } from '@camberi/firecms';

export type Staff = {
  name: {
    first: string;
    last: string;
  };
  excerpt?: string;
  contact?: {
    phone?: string;
    email?: string;
  },
  avatar: {
    image?: string;
    video?: string;
  };
  meta: {
    timeCreated?: Date;
    slug: string;
  };
};

export const staffSchema = buildSchema<Staff>({
  name: 'Portfolio',
  properties: {
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'map',
      properties: {
        first: {
          title: 'First',
          validation: { required: true },
          dataType: 'string',
        },
        last: {
          title: 'Last',
          validation: { required: true },
          dataType: 'string',
        },
      },
    },
    contact: {
      title: 'Contact info',
      dataType: 'map',
      properties: {
        email: {
          title: 'Email',
          dataType: 'string',
        },
        phone: {
          title: 'Phone',
          dataType: 'string',
        },
      },
    },
    excerpt: {
      title: 'Excerpt',
      dataType: 'string',
    },
    avatar: ({ entityId }) =>
      buildProperty({
        title: 'Backdrop',
        dataType: 'map',
        properties: {
          image: buildProperty({
            dataType: 'string',
            title: 'Image',
            disabled: entityId
              ? false
              : {
                  clearOnDisabled: true,
                  disabledMessage:
                    'You have to create entity before uploading any files.',
                },
            config: {
              storageMeta: {
                mediaType: 'image',
                storagePath: `willProcess/images/staff/${entityId}`,
                acceptedFiles: ['image/*'],
                metadata: {
                  cacheControl: 'max-age=1000000',
                },
              },
            },
          }),
          video: buildProperty({
            dataType: 'string',
            title: 'Video',
            disabled: entityId
              ? false
              : {
                  clearOnDisabled: true,
                  disabledMessage:
                    'You have to create entity before uploading any files.',
                },
            config: {
              storageMeta: {
                mediaType: 'video',
                storagePath: `videos/staff/${entityId}`,
                acceptedFiles: ['video/*'],
                metadata: {
                  cacheControl: 'max-age=1000000',
                },
              },
            },
          }),
        },
      }),
    meta: {
      title: 'Metadata',
      dataType: 'map',
      properties: {
        timeCreated: {
          title: 'Time joined',
          dataType: 'timestamp',
        },
        slug: {
          title: 'URL Slug',
          dataType: 'string',
          validation: {
            required: true,
            lowercase: true,
            trim: true,
          },
          description:
            'Make sure the slug is URI encodable, I.E only containing letters, numbers, and hyphens.',
        },
      },
    },
  },
});
