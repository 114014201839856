import { buildProperty, buildSchema, EntityReference } from '@camberi/firecms';

export type Service = {
  title: string;
  excerpt?: string;
  content: string;
  icon: string;
  meta: {
    author?: EntityReference;
    published: boolean;
    timePublished?: Date;
    timeUpdated?: Date;
    slug: string;
  };
};

export const serviceSchema = buildSchema<Service>({
  name: 'Portfolio',
  properties: {
    title: {
      title: 'Title',
      validation: { required: true },
      dataType: 'string',
    },
    excerpt: {
      title: 'Excerpt',
      dataType: 'string',
      config: {
        multiline: true,
        markdown: true,
      },
    },
    content: {
      title: 'Content',
      validation: { required: true },
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    icon: ({ entityId }) =>
      buildProperty({
        dataType: 'string',
        title: 'Icon',
        disabled: entityId
          ? false
          : {
              clearOnDisabled: true,
              disabledMessage:
                'You have to create entity before uploading any files.',
            },
        config: {
          storageMeta: {
            mediaType: "image",
            storagePath: `images/services/${entityId}`,
            acceptedFiles: ['image/*'],
            metadata: {
              cacheControl: 'max-age=1000000',
            },
          },
        },
      }),
    meta: {
      title: 'Metadata',
      dataType: 'map',
      properties: {
        author: {
          title: 'Author',
          dataType: 'reference',
          path: 'staff',
        },
        published: {
          title: 'Published',
          dataType: 'boolean',
        },
        timePublished: {
          title: 'Time published',
          dataType: 'timestamp',
          autoValue: 'on_create',
        },
        timeUpdated: {
          title: 'Time updated',
          dataType: 'timestamp',
          autoValue: 'on_update',
        },
        slug: {
          title: 'URL Slug',
          dataType: 'string',
          validation: {
            required: true,
            lowercase: true,
            trim: true,
          },
          description:
            'Make sure the slug is URI encodable, I.E only containing letters, numbers, and hyphens.',
        },
      },
    },
  },
});
