import { buildProperty, buildSchema, EntityReference } from '@camberi/firecms';

export type Case = {
  title: string;
  excerpt?: string;
  content: string;
  backdrop: {
    image?: string;
    video?: string;
  };
  meta: {
    author?: EntityReference;
    published: boolean;
    timePublished?: Date;
    timeUpdated?: Date;
    slug: string;
  };
  services: EntityReference[];
};

export const caseSchema = buildSchema<Case>({
  name: 'Portfolio',
  properties: {
    title: {
      title: 'Title',
      validation: { required: true },
      dataType: 'string',
    },
    excerpt: {
      title: 'Excerpt',
      dataType: 'string',
      config: {
          multiline: true
      }
    },
    content: {
      title: 'Content',
      validation: { required: true },
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    backdrop: ({ entityId }) =>
      buildProperty({
        title: 'Backdrop',
        dataType: 'map',
        properties: {
          image: buildProperty({
            dataType: 'string',
            title: 'Image',
            disabled: entityId
              ? false
              : {
                  clearOnDisabled: true,
                  disabledMessage:
                    'You have to create case before uploading any files.',
                },
            config: {
              storageMeta: {
                mediaType: 'image',
                storagePath: `willProcess/images/portfolio/${entityId}`,
                acceptedFiles: ['image/*'],
                metadata: {
                  cacheControl: 'max-age=1000000',
                },
              },
            },
          }),
          video: buildProperty({
            dataType: 'string',
            title: 'Video',
            disabled: entityId
              ? false
              : {
                  clearOnDisabled: true,
                  disabledMessage:
                    'You have to create case before uploading any files.',
                },
            config: {
              storageMeta: {
                mediaType: 'video',
                storagePath: `videos/portfolio/${entityId}`,
                acceptedFiles: ['video/*'],
                metadata: {
                  cacheControl: 'max-age=1000000',
                },
              },
            },
          }),
        },
      }),
    meta: {
      title: 'Metadata',
      dataType: 'map',
      properties: {
        author: {
          title: 'Author',
          dataType: "reference",
          path: "staff"
        },
        published: {
          title: 'Published',
          dataType: 'boolean',
        },
        timePublished: {
          title: 'Time published',
          dataType: 'timestamp',
          autoValue: "on_create"
        },
        timeUpdated: {
          title: 'Time updated',
          dataType: 'timestamp',
          autoValue: "on_update"
        },
        slug: {
          title: 'URL Slug',
          dataType: 'string',
          validation: {
            required: true,
            lowercase: true,
            trim: true,
          },
          description:
            'Make sure the slug is URI encodable, I.E only containing letters, numbers, and hyphens.',
        },
      },
    },
    services: {
      title: 'Services',
      description: 'Services related to this case',
      dataType: 'array',
      of: {
        dataType: "reference",
        path: "services"
      },
    },
  },
});
